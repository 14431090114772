html,
body,
#root {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
  width: 100%;
  height: 100%;
}

body {
  background: #bdbbbb;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: #333;
}

.app {
  height: 100%;
}
.app.pan {
  cursor: move;
}

.items {
  position: fixed; bottom: 20px;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: flex; align-items: center;
  color: #333; font-size: 12px;
  background-color: #f3f3f3;
  padding: 0 10px; border-radius: 4px;
}

.items .item {
  padding: 2px 20px; 
  text-align: center;
  cursor: pointer;
  margin: 5px;
  position: relative;
}

.items .item.active, .items .item:hover {
  background: #ccc;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.items .item>.children {
  position: absolute;
  bottom: 70px;
  display: flex;
  background: #f3f3f3;
  color: #333;
  border-radius: 4px;
  word-break: keep-all;
  left: -95px;
}

.items .item>.children>.item {
  padding: 2px 10px;
}

.items .item .children.hide {
  display: none;
}

.items .item .children.bloom {
  padding: 15px 20px;
  left: -90px;
}

.items .item .children.bloom>input {
  cursor: pointer;
}